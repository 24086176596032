export class BaseModel<T> {
  protected _data: T;
  constructor(data: T) {
    this._data = data;
  }

  static fromArray<T>(data: T[]) {
    if ((data as any)?.edges?.length > 0) {
      data = (data as any).edges.map((item: any) => item.node);
    }

    return data?.length > 0 ? data.map(item => new this(item)) : [];
  }

  static fromArrayExtending<T>(data: T[], extending: any) {
    if ((data as any)?.edges?.length > 0) {
      data = (data as any).edges.map((item: any) => item.node);
    }

    return data?.length > 0 ? data.map(item => new this({ ...item, ...extending })) : [];
  }
}
