import { BaseModel } from './base.model';
import { calculateItemRefund } from '@helpers';
import { creditedMap } from '@helpers';

export class ClaimItemModel extends BaseModel<any> {
  get id() {
    return this._data.id;
  }

  get photo() {
    return this._data.imageUrl;
  }

  get creditStore() {
    const resolution = this.claim.resolutions.find(resolution => resolution.id === this.resolutionId);
    return resolution.creditStore;
  }

  get details() {
    return this._data.details;
  }

  get productImage() {
    return this.photo;
  }

  get claim() {
    return this._data.claim;
  }

  get order() {
    return this.claim.order;
  }

  get quantity() {
    return this._data.quantity;
  }

  get resolutionId() {
    return this._data.resolutionId;
  }

  get refundedQuantity() {
    return this._data?.orderItem?.refundedQuantity;
  }

  get validQuantity() {
    const quantity = this._data?.orderItem?.quantity;
    const refundedQuantity = this.refundedQuantity;
    const claimQuantity = this.quantity;
    const remainingQuantity = quantity - refundedQuantity;

    if (remainingQuantity === 0 || claimQuantity > remainingQuantity) {
      return remainingQuantity;
    }

    return claimQuantity;
  }

  get title() {
    return this._data.name;
  }

  get orderId() {
    return this._data.orderId;
  }

  get productName() {
    return this.title;
  }

  get variation() {
    return '';
  }

  get orderItem() {
    return (
      this.claim.order.products.find(
        (p: any) => p.sourceItemId === this._data.sourceItemId && p.sourceVariantId === this._data.sourceVariantId,
      ) || null
    );
  }

  get currency() {
    if (!this.claim.order.products || this.claim.order.products.length === 0) {
      return 'USD';
    }

    return this.orderItem?.currency || 'USD';
  }

  get price() {
    if (!this.claim.order.products || this.claim.order.products.length === 0) {
      return 0;
    }

    return Number(this.orderItem?.price) || 0;
  }

  get sku() {
    return this._data.sku;
  }

  get selectedOptions() {
    return this?.orderItem?.selectedOptions ?? [];
  }

  get images() {
    return this._data.images;
  }

  get issue() {
    return {
      quantity: this.quantity,
      details: this.details,
      issueType: this.claimType,
      images: this.images,
    };
  }

  get discount() {
    if (!this.claim.order.products || this.claim.order.products.length === 0) {
      return 0;
    }

    return Number(this.orderItem?.discount) || 0;
  }

  get sourceVariantId() {
    return this._data.sourceVariantId;
  }
  get netPrice() {
    if (!this.claim.order.products || this.claim.order.products.length === 0) {
      return 0;
    }
    const price = Number(this.orderItem?.price) - Number(this.orderItem.discount);

    return price.toFixed(3);
  }

  get priceInDollars() {
    if (!this.claim.order.products || this.claim.order.products.length === 0) {
      return 0;
    }
    const price = (Number(this.orderItem?.price) - Number(this.orderItem.discount)) * this.exchangeRate;

    return price.toFixed(3);
  }

  get tax() {
    return parseFloat(this.orderItem?.tax ?? 0).toFixed(3);
  }

  get exchangeRate() {
    return this.claim.order.exchangeRate;
  }

  get taxInDollars() {
    const price = parseFloat(this.orderItem?.tax ?? 0) * this.exchangeRate;

    return price.toFixed(3);
  }

  get priceBeforeDiscounts() {
    if (!this.claim.order.products || this.claim.order.products.length === 0) {
      return 0;
    }

    const price = this.orderItem?.price * this.exchangeRate;

    return price.toFixed(2);
  }

  get claimType() {
    return this._data.claimType || 'Unknown';
  }

  set claimType(claimType) {
    this._data.claimType = claimType;
  }

  get sourceProductId() {
    return this._data.sourceProductId;
  }

  get resolutionRequested() {
    return this._data.resolutionRequested;
  }

  get resolutionQuantity() {
    const resolutionQuantity = this._data.resolutionQuantity;
    return resolutionQuantity ? resolutionQuantity : 0;
  }

  set resolutionQuantity(quantity) {
    this._data.resolutionQuantity = quantity;
  }

  set resolutionPercentage(percentage) {
    this._data.resolutionPercentage = percentage;
  }

  set resolutionFinal(resolution) {
    this._data.resolutionFinal = resolution;
  }

  get resolutionPercentage() {
    return this._data.resolutionPercentage;
  }

  get resolutionFinal() {
    return this._data.resolutionFinal;
  }

  get sourceItemId() {
    return this._data.sourceItemId;
  }

  get sourceOrderId() {
    return this._data.sourceOrderId;
  }
}
